import React, { useContext } from 'react';
import { SideNav, SideNavItem } from '@spglobal/react-components';
import { Link as RouterLink } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { getRole, getNBRole } from '../../utils/user';
import {
    ALLOWED_CLIENT_ADMIN,
    ALLOWED_SUPER_ADMIN,
    GroupAndResourceMapping,
    NOT_ALLOWED,
    RBAC_ROLES,
    RBAC_ROLES_NB,
} from '../../utils/constants';
import { getFile } from '../../services/fileDownloadService';
import { useApiGet } from '../../hooks/useApiGet';

// import ClimanomicsPDF from '../Methodology/assets/SPS1_Climanomics_Methodology_Feb2025.pdf';

/**
 * Component with left side navigation
 * @returns {JSX.Element}
 * @constructor
 */
export default function MainDrawer() {
    const { user } = useContext(UserContext);
    const { data: appConfigs } = useApiGet('api/appConfig', { keys: 'nb_url' });
    const nb_url = appConfigs?.results?.find((i) => i.key === 'nb_url').value;
    const { data: Mrp_menu } = useApiGet('api/appConfig', { keys: 'Mrp_menu' });
    const Mrp_menu_Disabled =
        Mrp_menu?.results?.find((i) => i.key === 'Mrp_menu_Disabled').value === 'true';

    // Full URL
    const fullUrl = window.location.href;

    const isEUorCADomain = (url) =>
        url.toLowerCase().includes('eu.climanomics.spglobal.com') ||
        url.toLowerCase().includes('eu-stg.climanomics.spglobal.com') ||
        url.toLowerCase().includes('ca.climanomics.spglobal.com') ||
        url.toLowerCase().includes('ca-stg.climanomics.spglobal.com');

    const allowedSidebarItem = (sidebar) => {
        const role = getRole(user && user.SPGGroups ? user.SPGGroups : []);
        const roleNB = getNBRole(user && user.SPGGroups ? user.SPGGroups : []);

        // If user doesnot belong any roles in climanomics and doesntnot belong to NB login user role
        if ((!role || !RBAC_ROLES[role]) && (!roleNB || !RBAC_ROLES_NB[roleNB])) {
            return false;
        }

        // If user belongs to NB login user role and looking for nature-risk menu then enable it
        if (sidebar === 'nature-risk' && roleNB && RBAC_ROLES_NB[roleNB]) {
            return true;
        }

        // If user belong to any roles in climanomics ( NB login user check is done in previous if condition)
        if (role && RBAC_ROLES[role]) {
            if (
                sidebar === 'manage' ||
                sidebar === 'climate-risk' ||
                sidebar === 'all-assets' ||
                sidebar === 'assets-types' ||
                sidebar === 'impact-functions' ||
                sidebar === 'hazards' ||
                sidebar === 'model-methodology-download' ||
                sidebar === 'release-notes'
            )
                return true;
            // Dont write else fasle here as it has continue for other type of menus
        }

        const allowedGroupsResourceList = GroupAndResourceMapping()[role];
        // const allowedGroupsResourceListNB = GroupAndResourceMapping()[roleNB];
        let isAllow = false;

        if (!allowedGroupsResourceList || allowedGroupsResourceList.length === 0) {
            return false;
        }

        if (sidebar === 'multiple-return-period') {
            // Allow CLIM_ADMIN and CLIM_EDITOR to see the menu
            if (role === 'CLIM_ADMIN' || role === 'CLIM_EDITOR') {
                return true;
            }
        }

        if (sidebar === 'customer-management') {
            allowedGroupsResourceList.forEach((resourceObj) => {
                if (
                    resourceObj.access !== NOT_ALLOWED &&
                    resourceObj.resource &&
                    resourceObj.resource.length
                ) {
                    resourceObj.resource.forEach((resource) => {
                        if (resource === 'Customers') {
                            isAllow = true;
                        }
                    });
                }
            });
        } else if (sidebar === 'super-admin') {
            isAllow = ALLOWED_SUPER_ADMIN.includes(role);
        } else if (sidebar === 'client-admin') {
            isAllow = ALLOWED_CLIENT_ADMIN.includes(role);
        } else if (
            sidebar === 'exports' ||
            sidebar === 'summary-report' ||
            sidebar === 'multiple-return-period'
        ) {
            allowedGroupsResourceList.forEach((resourceObj) => {
                if (
                    resourceObj.access !== NOT_ALLOWED &&
                    resourceObj.resource &&
                    resourceObj.resource.length
                ) {
                    resourceObj.resource.forEach((resource) => {
                        if (
                            resource === 'ExportLibrary' ||
                            resource === 'ActiveCustomerExport' ||
                            resource === 'TrialCustomerExport' ||
                            resource === 'DemoCustomerExport'
                        ) {
                            isAllow = true;
                        }
                    });
                }
            });
        } else if (sidebar === 'dashboard') {
            allowedGroupsResourceList.forEach((resourceObj) => {
                if (
                    resourceObj.access !== NOT_ALLOWED &&
                    resourceObj.resource &&
                    resourceObj.resource.length
                ) {
                    resourceObj.resource.forEach((resource) => {
                        if (resource === 'ProcessingStatusDashboard') isAllow = true;
                    });
                }
            });
        } else {
            return false;
        }

        return isAllow;
    };

    const fileKey = 'SPS1_Climanomics_Methodology_Feb2025.pdf';

    const handleDocumentClick = async () => {
        try {
            const response = await getFile(fileKey);

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileKey); // Set the file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
    return (
        <SideNav hidePins hideSearch>
            <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                {allowedSidebarItem('climate-risk') && (
                    <RouterLink className="spg-sidenav-dropdown__link" to="/real-assets">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Climate Risk</span>
                        </span>
                    </RouterLink>
                )}
                {allowedSidebarItem('nature-risk') && (
                    <a
                        className="spg-sidenav-dropdown__link"
                        href={nb_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="spg-sidenav-dropdown__text">
                            <span>Nature Risk</span>
                        </span>
                    </a>
                )}
            </li>

            {allowedSidebarItem('manage') && (
                <SideNavItem title="Manage" isPrimary>
                    <li className="spg-sidenav-dropdown__item">
                        {allowedSidebarItem('all-assets') && (
                            <RouterLink className="spg-sidenav-dropdown__link" to="/manage">
                                <span className="spg-sidenav-dropdown__text">
                                    <span>All Assets</span>
                                </span>
                            </RouterLink>
                        )}
                    </li>
                    <li className="spg-sidenav-dropdown__item">
                        {allowedSidebarItem('assets-types') && (
                            <RouterLink className="spg-sidenav-dropdown__link" to="/asset-types">
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Asset Types</span>
                                </span>
                            </RouterLink>
                        )}
                    </li>
                    <li className="spg-sidenav-dropdown__item">
                        {allowedSidebarItem('impact-functions') && (
                            <RouterLink
                                className="spg-sidenav-dropdown__link"
                                to="/impact-functions"
                            >
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Impact Functions</span>
                                </span>
                            </RouterLink>
                        )}
                    </li>
                    <li className="spg-sidenav-dropdown__item">
                        {allowedSidebarItem('hazards') && (
                            <RouterLink className="spg-sidenav-dropdown__link" to="/hazards">
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Hazards</span>
                                </span>
                            </RouterLink>
                        )}
                    </li>
                    {allowedSidebarItem('dashboard') && (
                        <li className="spg-sidenav-dropdown__item">
                            <RouterLink
                                className="spg-sidenav-dropdown__link"
                                to="/bulk-import-track-file"
                            >
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Import</span>
                                </span>
                            </RouterLink>
                        </li>
                    )}
                    {allowedSidebarItem('exports') && (
                        <li className="spg-sidenav-dropdown__item">
                            <RouterLink className="spg-sidenav-dropdown__link" to="/export">
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Export</span>
                                </span>
                            </RouterLink>
                        </li>
                    )}
                    {!isEUorCADomain(fullUrl) &&
                        !Mrp_menu_Disabled &&
                        allowedSidebarItem('multiple-return-period') && (
                            <li className="spg-sidenav-dropdown__item">
                                <RouterLink
                                    className="spg-sidenav-dropdown__link"
                                    to="/multiple-return-period"
                                >
                                    <span className="spg-sidenav-dropdown__text">
                                        <span>Multiple Return Period</span>
                                    </span>
                                </RouterLink>
                            </li>
                        )}
                    {allowedSidebarItem('summary-report') && (
                        <li className="spg-sidenav-dropdown__item">
                            <RouterLink className="spg-sidenav-dropdown__link" to="/summary-report">
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Summary Reports</span>
                                </span>
                            </RouterLink>
                        </li>
                    )}
                    {allowedSidebarItem('customer-management') && (
                        <li className="spg-sidenav-dropdown__item">
                            <RouterLink className="spg-sidenav-dropdown__link" to="/manageCustomer">
                                <span className="spg-sidenav-dropdown__text">
                                    <span>Customers</span>
                                </span>
                            </RouterLink>
                        </li>
                    )}
                </SideNavItem>
            )}
            <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                {allowedSidebarItem('release-notes') && (
                    <RouterLink className="spg-sidenav-dropdown__link" to="/whatsnew">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Release Notes</span>
                        </span>
                    </RouterLink>
                )}
            </li>
            {allowedSidebarItem('model-methodology-download') && (
                <SideNavItem title="Real Assets Modeling Methodology" isPrimary>
                    <li className="spg-sidenav-dropdown__item">
                        <button
                            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                            href="#"
                            className="spg-sidenav-dropdown__link"
                            onClick={handleDocumentClick}
                        >
                            <span className="spg-sidenav-dropdown__text">
                                <span>Modeling Methodology Download</span>
                            </span>
                        </button>
                    </li>
                </SideNavItem>
            )}
            {allowedSidebarItem('super-admin') && (
                <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/rbac-super-admin">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Role Based Access Control</span>
                        </span>
                    </RouterLink>
                </li>
            )}
            {allowedSidebarItem('client-admin') && (
                <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/rbac-client-admin">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Role Based Access Control</span>
                        </span>
                    </RouterLink>
                </li>
            )}
            {true && (
                <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                    <RouterLink className="spg-sidenav-dropdown__link" to="">
                        {/* <span className="spg-sidenav-dropdown__text">
                            <span>Dummy link to hide the component error</span>
                        </span> */}
                    </RouterLink>
                </li>
            )}
        </SideNav>
    );
}
